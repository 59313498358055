var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.qs ? _c('div', [_c('SldDetails', {
    attrs: {
      "sld-data": _vm.sldData,
      "sld-open": _vm.sldOpen
    },
    on: {
      "close": _vm.closeDrawer
    }
  }), _c('div', [_c('Row', [_c('Col', {
    attrs: {
      "span": "24"
    }
  }, [_c('FilterTable', {
    attrs: {
      "name": "Trackers",
      "container": true,
      "fetch-items": this.getCookies,
      "export-csv": _vm.exportCsv,
      "fetch-items-params": {
        sort: 'Cookies.name',
        properties: [this.selectedProperty.id],
        page: 1,
        period: _vm.daterange
      },
      "namespace": "cookie",
      "columns": _vm.columns,
      "limit": 30,
      "search-filters": ['search', 'show-category-exemption', 'isExempted', 'cmpScenario']
    }
  })], 1)], 1)], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }