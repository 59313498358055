<template>
  <div v-if="qs">
    <SldDetails :sld-data="sldData"
                :sld-open="sldOpen"
                @close="closeDrawer"
    />
    <div>
      <Row>
        <Col span="24">
          <!-- <Alert type="warning"
                 style="height:inherit!important"
          >
            <p>the tracker and request data is very large. They are only accessible by a maximum range of 7 days on the selected daterange in the datepicker</p>
            <br>
            <Form>
              <FormItem>
                <Select placeholder="Select range"
                        v-model="forcedDate"
                >
                  <Option v-for="(item, k) in daterangeList"
                          :value="item.value"
                          :key="k"
                  >
                    {{ item.value }}
                  </Option>
                </Select>
              </FormItem>
            </Form>
          </Alert> -->
          <FilterTable
            name="Trackers"
            :container="true"
            :fetch-items="this.getCookies"
            :export-csv="exportCsv"
            :fetch-items-params="{sort: 'Cookies.name',
                                  properties: [this.selectedProperty.id],
                                  page: 1,
                                  period: daterange }"
            namespace="cookie"
            :columns="columns"
            :limit="30"
            :search-filters="['search', 'show-category-exemption', 'isExempted', 'cmpScenario']"
          />
        </Col>
      </Row>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import FilterTable from '@/components/ui/FilterTable'
import pageConfig from '@/mixins/pageConfig'
import tableColumn from '@/mixins/tableColumn'
import SldDetails from '@/views/Cookies/SldDetails'
import moment from 'moment'
export default {
  name: 'CookieList',
  components: {
    FilterTable,
    SldDetails
  },
  mixins: [
    pageConfig,
    tableColumn
  ],
  data () {
    return {
      loading: true,
      modal: false,
      sldOpen: false,
      sldData: {},
      moment: moment
    }
  },
  methods: {
    ...mapActions({
      getCookies: 'cookie/getCookies',
      exportCsv: 'cookie/exportCsv',
      getTraquersSld: 'cookie/getPgTrackers'
    }),
    doesHelperColumn () {
      const { showCategoryHelper } = this
      return showCategoryHelper 
        ? {
          key: 'Category.name',
          title: this.$t('global.table.category'),
          sortable: 'custom',
          sortKey: 'Categories.name',
          render: (h, params) => {
            if (!(params.row.vendor && params.row.vendor.category && params.row.vendor.category.description)) return h('span', {}, 'Under qualification')
            return h('Poptip', {
              props: {
                content: (params.row.vendor && params.row.vendor.category && params.row.vendor.category.description) ? params.row.vendor.category.description : 'Under qualification',
                placement:"right",
                'word-wrap': true,
                style:{wordWrap: 'break-word'},
                trigger:'hover'
              }
            }, [
              h('Badge', {
                slot:"title",
                props: {
                  color: this.complianceColor[this.vendorVigilanceToLetter(params.row.vendor.category.vigilance_id)],
                  text: this.$t('global.vendor.category_vigilance') + ' ' + this.vendorVigilanceToLetter(params.row.vendor.category.vigilance_id)
                }
              }),
              h('span', {}, [
                params.row.vendor.category.is_exempted ? h('i', {class:"fas fa-check-double", style:{marginRight:'5px', color:"#1890ff"}}) : '',
                params.row.vendor.category.name
              ])
            ])
          }}
        : {
          title: this.$t('global.table.category'),
          sortKey: 'Categories.name',
          sortable: 'custom',
          render: (h, params) => h('span', {}, [
            params.row.vendor.category.is_exempted ? h('i', {class:"fas fa-check-double", style:{marginRight:'5px', color:"#1890ff"}}) : '',
            params.row.vendor.category.name
          ])
        }
     
    },
    getCookieAgeInMonthOrDays (days) {
      days = parseInt(days)
      if (typeof days !== 'number') return 'N/A'
      if (days > -31 && days < 31) return days + ' ' + this.$t('global.time.days')
      const month = Math.round(days / 30.5)
      return month + ' ' + this.$t('global.time.months')
    },
    
    clickTracker (data, context) {
      data.context = context
      this.sldData = data
      this.sldOpen = true
    },
    closeDrawer () {
      this.sldOpen = false
      this.sldData = {}
    }

  },
  watch: {
    qs: {
      handler (val, old) {
        this.forcedDate = ''
      }
    }
  },
  computed: {
    ...mapGetters({
      userHasRole: 'user/hasRole',
      showCategoryHelper: 'vendor/showCategoryHelper',
      vendorVigilanceToLetter: 'vendor/vendorVigilanceToLetter',
      qs: 'scope/qs',
      complianceColor: 'vendor/riskColor',
      daterange: 'scope/selectedDaterange',
      selectedProperty: 'scope/selectedProperty'
    }),
    columns () {
      return [
        {
          title: 'Name',
          key: 'Cookies.name',
          sortable: 'custom',
          sortKey: 'Cookies.name',
          render: (h, params) => {
            const t = this
            return h('a', {
              on: {
                click: () => {t.clickTracker(params.row, 'tracker')}
              }
            }, params.row.name)
          }
        },
        {
          title: this.$t('global.filters.is_exempted'),
          sortable: 'custom',
          sortKey: 'Cookies.is_exempted',
          render: (h, params) => {
            const render = h('Tag', {
              props: {
                color: 'blue'
              }
            }, 'Exempted')
            return params.row.is_exempted === 1 ? render : ''
          }
        },
        {
          key: 'Cookies.host',
          title: this.$t('global.table.domain'),
          render: (h, params) => {
            const t = this
            return h('a', {
              on: {
                click: () => {t.clickTracker(params.row, 'request')}
              }
            }, params.row.host)
          }
        },
        this.createColumn(this.$t('global.table.party'), 'key', 'Cookies.is_first_party', row => ({
          content: row.isFirstParty ? '1st party' : '3rd party'
        })),
        {
          title: 'Initiator',
          sortKey: 'Cookies.initiator_vendor_name_tmp',
          key: 'initiator_vendor_name_tmp',
          sortable: 'custom',
          render: (h, params) => {
             
            return params?.row?.initiator_vendor?.uuid
              ? h('router-link', {
                props: {
                  to: `/vendors/${params.row.initiator_vendor.uuid}`
                },
                style: {
                  fontWeight: 'bold'
                },
                attrs: {
                  title: params?.row?.initiator_host
                }
              }, params.row.initiator_vendor.name || 'N/A')
              : h('span', {
                class:"toto",
                attrs: {
                  title: params?.row?.initiator_host
                }
              }, 'N/A')
          }
        },
        {
          title: 'Initiator category',
          sortKey: 'Cookies.initiator_vendor_category_tmp',
          key: 'initiator_vendor_category',
          sortable: 'custom'
        },
        this.createColumn(this.$t('global.table.vendor'), 'vendor', 'Vendors.name', row => {
          if (row.vendor && row.vendor.name) {
            return {
              tag: 'router-link',
              props: {to: `/vendors/${row.vendor.uuid}`},
              content: row => ({tag: 'strong', content: row.vendor.name})
            }
          }
          return {tag: 'span', content: (row.vendor && row.vendor.name) ? row.vendor.name : 'Under qualification'}
        }),
        this.doesHelperColumn(),
        // this.createColumn(this.$t('global.table.category'), 'categories', 'Categories.name', row => ({
        //   content: (row.vendor && row.vendor.category && row.vendor.category.name) ? row.vendor.category.name : 'Under qualification'
        // })),
        this.createColumn(this.$t('global.table.type'), 'type', 'Cookies.type', null, 90),
        this.createColumn(this.$t('global.table.lifetime'), 'lifetime', 'Cookies.lifetimeDays', row => ({
          content: this.getCookieAgeInMonthOrDays(row.lifetimeDays)
        })),
        {
          key: 'cmp_scenario_outcome',
          sortable: 'custom',
          sortKey: 'Cookies.cmp_scenario_outcome',
          title: 'Scenario',
          render: (h, params) => h('span', {}, params.row.cmp_scenario_outcome === 'ACCEPT' ? 'With explicit choice' : params.row.cmp_scenario_outcome === 'REJECT' ? 'Despite refusal' : 'Without explicit choice' )

        }
      ]
    }
  }
}
</script>
